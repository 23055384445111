import { useFormContext } from "react-hook-form";
import { forwardRef } from "react";
import Label from "./Label";
import InputError from "./InputError";

// TODO: remove tailwindcss here

const FormTextArea = forwardRef(
	({ name, label, required, showErrors = true, ...props }, ref) => {
		const {
			register,
			formState: { errors, isSubmitting },
		} = useFormContext();

		return (
			<div className="flex flex-col">
				{label && (
					<Label required={required} htmlFor={name}>
						{label}
					</Label>
				)}
				<textarea
					className="mt-1.5 flex-grow border p-2 text-sm border-gray-300 focus-visible:border-gray-500"
					id={name}
					ref={ref}
					{...props}
					{...register(name)}
				/>
				{showErrors && errors[name] && (
					<InputError>{errors[name].message}</InputError>
				)}
			</div>
		);
	}
);

FormTextArea.displayName = "FormTextArea";

export default FormTextArea;
