import React from "react";
import style from "./SearchGroup.module.css";

import SearchItem from "../searchItem/SearchItem";

import { Command } from "cmdk";

function SearchGroup({ content, type, name }) {
	return (
		<Command.Group>
			<div className={style.group}>
				<div className={style.type}>
					<span>{name}</span>
					<div className={style.line}></div>
				</div>
				<div className={style.itemsHolder}>
					{content.map((item) => (
						<SearchItem key={item.id} type={type} item={item} />
					))}
				</div>
			</div>
		</Command.Group>
	);
}

export default SearchGroup;
