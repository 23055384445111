import React, { useEffect } from "react";
import style from "./ContentTable.module.css";
import Icon from "@/ui/icon/Icon"; // Adjust the import path as needed
import { SoundWaveIco, CurrentModuleIcon } from "@/ui/icon/CustomIcons"; // Adjust the import path as needed
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetCourseUnits } from "@/features/courses/hooks/useGetCourseUnits";
import { useState } from "react";
import { secondsToMinutesSeconds } from "@/utils/helpers";
import { useUser } from "@/features/auth/hooks/useUser";

import Button from "@/ui/button/Button";
import MarkAsCompletedButton from "@/features/courses/components/markAsCompletedButton/MarkAsCompletedButton";
import { COMPLETE_COURSE_THRESHOLD } from "@/utils/constants";

function ContentTable() {
	const { courseSlug, moduleSlug, unitSlug } = useParams();
	const { course, modules, isPending, isSuccess, isError, error } =
		useGetCourseUnits({ courseSlug });

	const { user } = useUser();

	const [openModules, setOpenModules] = useState([]);

	const isUnitLocked = (unit) => {
		if (unit.is_preview) return false;

		if (!user) return true;
		if (!course.is_free && !user.is_subscribed) return true;

		return false;
	};

	// open every module that is not completed
	useEffect(() => {
		if (openModules.length > 0) return;
		if (!modules) return;
		setOpenModules(
			modules
				.filter(
					(module) =>
						module.completion < 100 ||
						module.units.find((unit) => unit.slug == unitSlug)
				)
				.map((module) => module.slug)
		);
	}, [modules]);

	function toggleModule(slug) {
		if (!openModules.includes(slug)) {
			setOpenModules([...openModules, slug]);
		} else {
			setOpenModules(openModules.filter((slug2) => slug2 != slug));
		}
	}

	if (isError) return <>Error: {JSON.stringify(error)}</>;

	if (isPending || !isSuccess) return <Skelton />;

	const coursePercentComplete = Math.floor(
		(100 * course.completed_units) / course.total_units
	);

	return (
		<aside className={style.sidebar}>
			<div className={style.header}>
				<Link to="/" className={style.back}>
					<Icon icon="fa-circle-xmark" />
					<span>Idi nazad</span>
				</Link>
				<div className={style.progressHolder}>
					<div className={style.title}>{course.name}</div>
					<div className={style.body}>
						<div className={style.bar}>
							<div>{coursePercentComplete}% dovršeno</div>
							<div className={style.ratio}>
								<span>{course.completed_units}</span>
								<Icon icon="fa-slash-forward" />
								<span>{course.total_units}</span>
							</div>
						</div>
						<div className={style.progress}>
							<div
								className={style.fill}
								style={{
									"--progress-percent": `${coursePercentComplete}%`,
								}}
							></div>
						</div>
					</div>
					{coursePercentComplete >= COMPLETE_COURSE_THRESHOLD &&
						!course?.is_completed && (
							<MarkAsCompletedButton course={course} />
						)}
				</div>
			</div>
			<div className={style.body}>
				<div className={style.modules}>
					{modules.map((module) => (
						<div
							key={module.slug}
							className={`${style.module} ${
								module.completion >= 100 ? style.completed : ""
							}`}
						>
							<div className={style.info}>
								<div className={style.icoHolder}>
									{module.completion >= 100 ? (
										<Icon
											icon="fa-square-check"
											iconType="fa-solid"
										/>
									) : module.slug == moduleSlug ? (
										<CurrentModuleIcon
											completion={module.completion}
										/>
									) : (
										<Icon icon="fa-layer-group" />
									)}
								</div>
								<div
									className={style.title}
									onClick={() => toggleModule(module.slug)}
								>
									{module.name}
								</div>
								<div
									className={`${style.expend} ${
										openModules.includes(module.slug)
											? style.active
											: ""
									}`}
									onClick={() => toggleModule(module.slug)}
								>
									<Icon icon="fa-chevron-down" />
								</div>
							</div>
							{openModules.includes(module.slug) && (
								<div className={style.units}>
									{module.units.map((unit) => (
										<Link
											to={`/courses/${courseSlug}/${module.slug}/${unit.slug}/`}
											key={unit.slug}
											className={`
												${style.unit} 
												${unit?.user_activity?.is_completed ? style.completed : ""}
												${isUnitLocked(unit) ? style.locked : unit.slug == unitSlug ? style.active : ""}
											`}
										>
											<div className={style.icoHolder}>
												{isUnitLocked(unit) ? (
													<span
														className={
															style.lockedIco
														}
													>
														<Icon icon="fa-lock" />
													</span>
												) : unit.slug == unitSlug ? (
													unit.question_count ? (
														<Icon icon="fa-question" />
													) : (
														<SoundWaveIco />
													)
												) : (
													<Icon
														icon={
															unit.unit_type ===
															"video"
																? "fa-film"
																: "fa-seal-question"
														}
													/>
												)}
											</div>

											<div className={style.title}>
												{unit.name}
											</div>
											<div className={style.duration}>
												{unit?.is_liked && (
													<span
														className={
															style.likedIco
														}
													>
														<Icon icon="fa-heart" />
													</span>
												)}
												{unit?.user_activity
													?.is_completed && (
													<span
														className={
															style.completedIco
														}
													>
														<Icon icon="fa-check" />
													</span>
												)}
												<span>
													{unit.unit_type === "video"
														? secondsToMinutesSeconds(
																unit.video_duration
														  )
														: unit.question_count +
														  " pitanja"}
												</span>
											</div>
										</Link>
									))}
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</aside>
	);
}

function Skelton() {
	return <aside className={style.sidebar}></aside>;
}

export default ContentTable;
