import { request } from "@/services/network";

export async function getRecentCourses() {
	return request({
		url: "/courses/recent/",
		method: "GET",
	}).then((data) => {
		return {
			courses: data,
		};
	});
}

export async function getAllCourses() {
	const res = await request({
		url: "/courses/all/",
		method: "GET",
	}).then((data) => {
		return {
			courses: data.courses,
			categories: data.categories,
		};
	});
	return res;
}

export async function saveCourse({ course }) {
	return request({
		url: `/courses/${course.slug}/save/`,
		method: "POST",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function finishCourse({ course }) {
	return request({
		url: `/courses/${course.slug}/finish/`,
		method: "POST",
	});
}

export async function unsaveCourse({ course }) {
	return request({
		url: `/courses/${course.slug}/unsave/`,
		method: "POST",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function getContinueWatching() {
	return request({
		url: "/courses/continue-watching/",
		method: "GET",
	}).then((data) => {
		if (!data.length > 0) return null;

		return {
			video: data[0].video,
			courseLastWatched: data[0].course_last_watched,
			courseProgress: data[0].course_progress,
		};
	});
}

export async function getIncompleteCourses() {
	return request({
		url: "/courses/unfinished-courses/",
		method: "GET",
	}).then((data) =>
		data.map((item) => {
			return {
				video: item.video,
				courseLastWatched: item.course_last_watched,
				courseProgress: item.course_progress,
			};
		})
	);
}

export async function getCourse({ courseSlug }) {
	return request({
		url: `/courses/${courseSlug}/`,
		method: "GET",
	}).then((data) => {
		return {
			video: data?.video,
			quiz: data?.quiz,
			course: data?.course,
			unit_type: data?.unit_type,
		};
	});
}

export async function getUnit({ courseSlug, moduleSlug, unitSlug }) {
	return request({
		url: `/courses/${courseSlug}/${moduleSlug}/${unitSlug}/`,
		method: "GET",
	}).then((data) => {
		return {
			video: data?.video,
			quiz: data?.quiz,
			course: data?.course,
			unit_type: data?.unit_type,
		};
	});
}

export async function getCourseUnits({ courseSlug }) {
	return request({
		url: `/courses/${courseSlug}/units/`,
		method: "GET",
	}).then((data) => {
		return {
			course: data?.course,
			modules: data?.modules.map((module) => {
				const completion =
					module.units?.length > 0
						? Math.floor(
								(100 *
									module.units.filter(
										(unit) =>
											unit?.user_activity?.is_completed ==
											true
									).length) /
									module.units.length
						  )
						: 100;
				return { completion: completion, ...module };
			}),
		};
	});
}

export async function updateUserActivity({ unit, data }) {
	return request({
		url: `/courses/${unit.module.course.slug}/${unit.module.slug}/${unit.slug}/activity/`,
		method: "POST",
		data: data,
	}).then((data) => {
		return {
			success: true,
			shouldRefetchUnits: data.should_refetch_units,
		};
	});
}

export async function likeUnit({ unit }) {
	return request({
		url: `/courses/${unit.module.course.slug}/${unit.module.slug}/${unit.slug}/like/`,
		method: "POST",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function unlikeUnit({ unit }) {
	return request({
		url: `/courses/${unit.module.course.slug}/${unit.module.slug}/${unit.slug}/unlike/`,
		method: "POST",
	}).then((data) => {
		return {
			success: true,
		};
	});
}

export async function getMostLikedCourses() {
	return request({
		url: "/courses/most-liked/",
		method: "GET",
	}).then((data) => {
		return {
			courses: data,
		};
	});
}
