import style from "./Help.module.css";
import { Link, UNSAFE_ErrorResponseImpl } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import Container from "@/layouts/container/Container";
import Icon from "@/ui/icon/Icon";
import Label from "@/features/forms/components/Label";
import FormInput from "@/features/forms/components/FormInput";
import Spinner from "@/features/forms/components/Spinner";
import InputPhone from "@/features/forms/components/InputPhone";
import FormTextArea from "@/features/forms/components/FormTextArea";
import Button from "@/ui/button/Button";
import { useUser } from "@/features/auth/hooks/useUser";
import { useSendHelpTicket } from "@/features/auth/hooks/useSendHelpTicket";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import { successToast, errorToast } from "@/ui/toast/Toast";
import { useEffect, useState } from "react";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";

const ContactInfo = ({ user }) => (
	<div className={style.contactInfo}>
		{user &&
		user.phoneNumber &&
		user.firstName &&
		user.lastName &&
		user.email ? (
			<>
				<Label>Vaši kontakt podaci</Label>
				<div className={style.submiter}>
					<div className={style.imgHolder}>
						<img src={user?.safe_picture_url} alt="Profile" />
					</div>
					<span className={style.fullName}>
						{user?.firstName} {user?.lastName}
					</span>
					<div className={style.details}>
						<div className={style.detail}>
							<Icon icon="fa-at" />
							<span>{user?.email}</span>
						</div>
						<div className={style.detail}>
							<Icon icon="fa-phone" />
							<span>{user?.phoneNumber}</span>
						</div>
					</div>
				</div>
			</>
		) : (
			<>
				<FormInput
					name="full_name"
					label="Vaše ime i prezime"
					placeholder="Unesti ime i prezime"
					autoComplete="off"
					required
				/>
				<FormInput
					type="email"
					name="email"
					label="Email adresa"
					placeholder="Unesite email adresu"
					autoComplete="off"
					required
				/>
				<InputPhone
					name="phone_number"
					label="Broj telefona"
					placeholder="Unesite broj telefona"
					required
				/>
			</>
		)}
		<div className="mb-[2em]">
			<FormTextArea
				name="message"
				rows={3}
				required
				placeholder="Postavite pitanje"
				className={style.textarea}
				label="Opis problema"
			/>
		</div>
	</div>
);

const ContactMethods = ({
	contactMethods,
	activeContactMethod,
	handleContactMethodClick,
}) => (
	<div className={style.contactMethods}>
		<Label>Kontaktiraj me putem</Label>
		<div>
			{contactMethods.map((method, index) => (
				<div
					key={index}
					className={`${style.contactMethod} ${
						activeContactMethod === index ? style.active : ""
					}`}
					onClick={() => handleContactMethodClick(index)}
					role="button"
					tabIndex={0}
					onKeyPress={() => handleContactMethodClick(index)}
				>
					<Icon
						iconType={method.title === "Email" ? "far" : "fab"}
						icon={method.icon}
					/>
					<span>{method.title}</span>
				</div>
			))}
		</div>
	</div>
);

function Help() {
	const MIN_MESSAGE_LENGTH = 10;
	const [activeContactMethod, setActiveContactMethod] = useState(0);
	const { user, isPending, isLoading } = useUser();
	const { sendHelpTicket } = useSendHelpTicket();

	const contactMethods = [
		{ icon: "fa-envelope", title: "Email" },
		{ icon: "fa-facebook-messenger", title: "Messenger" },
		{ icon: "fa-whatsapp", title: "WhatsApp" },
		{ icon: "fa-viber", title: "Viber" },
		{ icon: "fa-telegram", title: "Telegram" },
	];

	const formMethods = useFormWithValidation({
		defaultValues: {
			preferredContactMethod: contactMethods[activeContactMethod].title,
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
		watch,
		setValue,
		getValues,
		reset,
	} = formMethods;

	useEffect(() => {
		if (user && !isPending) {
			setValue(
				"full_name",
				`${user?.firstName ?? ""} ${user?.lastName ?? ""}`.trim()
			);
			setValue("email", user?.email || "");
			setValue("phone_number", user?.phoneNumber || "");
		}
	}, [user, isPending]);

	const messageValue = watch("message", "");

	const onSubmit = async (data) => {
		const fullName =
			user && user.firstName && user.lastName
				? `${user?.firstName} ${user?.lastName}`
				: data.full_name;
		const email = user && user.email ? user.email : data.email;
		const phoneNumber =
			user && user.phoneNumber ? user.phoneNumber : data.phone_number;

		try {
			await sendHelpTicket({
				fullName,
				email,
				phoneNumber,
				contactMethod: data.preferredContactMethod,
				message: data.message,
			});
			successToast("Tiket za pomoć je uspješno poslan!");
			setValue("message", "");
		} catch (error) {
			errorToast("Tiket za pomoć nije poslan. Molimo pokušajte ponovo.");
			throw error;
		}
	};

	const handleContactMethodClick = (index) => {
		setActiveContactMethod(index);
		formMethods.setValue(
			"preferredContactMethod",
			contactMethods[index].title
		);
	};

	if (isPending && isLoading) return <FullPageLoader />;

	return (
		<Container size="medium">
			<div className={style.banner}>
				<div className={style.bannerText}>
					<div className={style.header}>
						<Link className={style.goBack} to="/">
							<Icon icon="fa-arrow-left" />
						</Link>
						<div className={style.breadcrumb}>
							<div className={style.item}>
								<span>Početna</span>
								<div className={style.separator}>
									<Icon icon="fa-slash-forward" />
								</div>
							</div>
							<div className={style.item}>
								<span>Centar za pomoć</span>
							</div>
						</div>
					</div>
					<h2>Prijavite grešku ili zatražite pomoć</h2>
					<p>
						Uočili ste problem ili vam je potrebna pomoć. Molimo Vas
						da nam se javite i opišete problem u par riječi. Naše
						osoblje će kontaktirati sa Vama u što kraćem vremenskom
						roku.
					</p>
				</div>
				<div className={style.ilustration}>
					<div>
						<div>
							<span>
								<Icon icon="fa-user" />
							</span>
							<span>
								<Icon icon="fa-envelope" />
							</span>
							<span></span>
						</div>
					</div>
				</div>
			</div>

			<FormProvider {...formMethods}>
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<div className={style.grid}>
						<ContactInfo user={user} />
						<ContactMethods
							contactMethods={contactMethods}
							activeContactMethod={activeContactMethod}
							handleContactMethodClick={handleContactMethodClick}
						/>
					</div>
					<div className={style.buttonHolder}>
						<Button
							type="accent"
							disabled={
								isSubmitting ||
								messageValue.trim().length < MIN_MESSAGE_LENGTH
							}
						>
							{isSubmitting ? <Spinner /> : <span>Pošalji</span>}
						</Button>
					</div>
				</form>
			</FormProvider>
		</Container>
	);
}

export default Help;
