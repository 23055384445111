import { useState, useRef, useEffect, useMemo } from "react";
import style from "./SearchParent.module.css";
import SearchInput from "../searchInput/SearchInput";
import SearchContent from "../searchContent/SearchContent";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { Command } from "cmdk";
import { useSearchIndex } from "../../hooks/useSearchIndex";
import * as JsSearch from "js-search";

export class BalkanSpecialCharsSanitize {
	replaceSpecialChars(str) {
		const charMap = {
			č: "c",
			ć: "c",
			đ: "d",
			ž: "z",
			š: "s",
			lj: "lj",
			nj: "nj",
		};

		return str
			.split("")
			.map((char) => charMap[char] || char)
			.join("");
	}

	sanitize(text) {
		return text
			? this.replaceSpecialChars(text.toLocaleLowerCase().trim())
			: "";
	}
}

function SearchParent() {
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [value, setValue] = useState("");
	const ref = useOutsideClick(() => setOpen(false));
	const inputRef = useRef(null);

	const { searchIndex, isPending, isSuccess, isError, error } =
		useSearchIndex();

	const searchObjects = useMemo(() => {
		return searchIndex.map((group, index) => {
			var search = new JsSearch.Search("id");

			search.sanitizer = new BalkanSpecialCharsSanitize();
			search.addIndex("title");

			if (group.type == "kursevi") {
				search.addIndex("category");
				search.addIndex("lecturers");
			} else if (group.type == "video") {
				search.addIndex("category");
			} else if (group.type == "quiz") {
				search.addIndex("category");
			} else if (group.type == "path") {
				search.addIndex("ectsCredits");
				search.addIndex("courseCount");
				search.addIndex("certificateId");
			}

			// search.addIndex(['author', 'name']); -> see JSSearch GitHub  for example
			// search.addIndex('tags') -> see JSSearch GitHub for example
			// npr za kurseve dodati lecturers
			search.addDocuments(group.content);
			return search;
		});
	}, [searchIndex]);

	const searchResults = useMemo(() => {
		if (search.length == 0) return searchIndex;

		return searchIndex.map((group, index) => {
			return {
				...group,
				content: searchObjects[index].search(search),
			};
		});
	}, [searchIndex, searchObjects, search]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if ((event.ctrlKey || event.metaKey) && event.key === "k") {
				event.preventDefault();
				event.stopPropagation();
				inputRef.current.focus();
				setOpen(true);
			}

			if (event.key === "Escape") {
				event.preventDefault();
				event.stopPropagation();
				setOpen(false);
				inputRef.current.blur();
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (open) {
			// scroll to top
			document.body.scrollTop = 0;
			document.body.style.overflow = "hidden";
			document.body.style.paddingRight = "8px";
		}

		return () => {
			document.body.style.overflow = "auto";
			document.body.style.paddingRight = "0";
		};
	}, [open]);

	return (
		<Command
			shouldFilter={false}
			value={value}
			open={open}
			ref={ref}
			onValueChange={(v) => setValue(v)}
			loop
		>
			<div className={style.holder}>
				<SearchInput
					value={search}
					onValueChange={setSearch}
					onFocus={() => setOpen(true)}
					ref={inputRef}
				/>
				<div className={style.content}>
					{open && (
						<SearchContent
							searchResults={searchResults}
							inputRef={inputRef}
						/>
					)}
				</div>
			</div>
		</Command>
	);
}

export default SearchParent;
