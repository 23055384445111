import { useApiSend } from "@/hooks/useApi";
import { finishCourse } from "../api";

export function useFinishCourse({ course }) {
	const { mutateAsync, isPending, isError, error } = useApiSend({
		mutationKeys: ["courses", course.slug, "finish"],
		invalidateKeys: [["courses", course.slug, "units", "list"]],
		fn: () => finishCourse({ course }),
	});

	return { finishCourse: mutateAsync, isPending, error, isError };
}
