import style from "./TranscriptSection.module.css";
import React, { useEffect, useState } from "react";

function formatTime(hours, minutes, seconds) {
	// Ensure minutes and seconds are two digits
	const formattedMinutes = String(minutes).padStart(2, "0");
	const formattedSeconds = String(seconds).padStart(2, "0");

	if (hours > 0) {
		// If hours exist, return HH:MM:SS
		const formattedHours = String(hours).padStart(2, "0");
		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	} else {
		// If no hours, return MM:SS
		return `${formattedMinutes}:${formattedSeconds}`;
	}
}

function groupTranscriptBlocks({ transcript, blockLength = 240 }) {
	const blocks = [];

	let currentBlock = [];
	let currentLength = 0;

	for (const subtitle of transcript) {
		if (
			currentBlock.length > 0 &&
			currentLength + subtitle.text.length > blockLength
		) {
			blocks.push(currentBlock);
			currentBlock = [];
			currentLength = 0;
		}

		currentLength += subtitle.text.length;
		currentBlock.push(subtitle);
	}

	if (currentBlock.length) blocks.push(currentBlock);
	return blocks;
}

function parseSRT(srt) {
	const subtitles = [];

	const lines = srt.split(/\r?\n/);
	let subtitle = {};
	let buffer = "";

	lines.forEach((line, index) => {
		if (/^\d+$/.test(line)) {
			// New subtitle block, save the previous subtitle
			if (subtitle.startTime && subtitle.endTime && buffer.trim()) {
				subtitle.text = buffer.trim();
				subtitles.push(subtitle);
			}

			// Start new subtitle block
			subtitle = {};
			buffer = "";
		} else if (line.includes("-->")) {
			// Extract start and end time
			const timeRegex = /(\d{2}):(\d{2}):(\d{2}),\d{3}/g;
			const times = line.match(timeRegex);

			if (times) {
				const [start, end] = times;

				const [startHours, startMinutes, startSeconds] = start
					.split(",")[0]
					.split(":")
					.map(Number);
				const [endHours, endMinutes, endSeconds] = end
					.split(",")[0]
					.split(":")
					.map(Number);

				subtitle.startSeconds =
					startSeconds + startMinutes * 60 + startHours * 3600;
				subtitle.endSeconds =
					endSeconds + endMinutes * 60 + endHours * 3600;

				subtitle.startTime = formatTime(
					startHours,
					startMinutes,
					startSeconds
				);
				subtitle.endTime = formatTime(endHours, endMinutes, endSeconds);
			}
		} else if (line.trim() !== "") {
			// Collect subtitle text
			buffer += line + " ";
		}

		// On the last line, push the final subtitle
		if (
			index === lines.length - 1 &&
			subtitle.startTime &&
			subtitle.endTime &&
			buffer.trim()
		) {
			subtitle.text = buffer.trim();
			subtitles.push(subtitle);
		}
	});

	return subtitles;
}

function TranscriptSection({ video, currentVideoSecond, onVideoTimeChange }) {
	const [transcript, setTranscript] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const handleTimeClick = (startSeconds) => {
		if (onVideoTimeChange) {
			onVideoTimeChange(startSeconds);
		}
	};

	async function fetchSubtitles() {
		if (!video?.subtitles) {
			setIsLoading(false);
			setTranscript(null);
			return;
		}

		try {
			setIsLoading(true);
			setIsError(false);
			const response = await fetch(video.subtitles);
			if (response.ok) {
				const text = await response.text();
				const parsed = parseSRT(text);
				const grouped = groupTranscriptBlocks({ transcript: parsed });
				setTranscript(grouped);
			} else {
				setIsError(true);
				setTranscript(null);
			}
		} catch (e) {
			console.error("Error fetching subtitles:", e);
			setIsError(true);
			setTranscript(null);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (video) {
			fetchSubtitles();
		} else {
			setIsLoading(false);
			setTranscript(null);
		}

		return () => {
			// Cleanup function
		};
	}, [video]);

	if (!video) {
		return (
			<div className={style.transcript}>
				<div className={style.transcriptBody}>
					<div className={`${style.transcriptItem}`}>
						<div className={style.time}>
							<span>00:00</span>
						</div>
						<div className={style.text}>
							<mark>Nema dostupnog videa</mark>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={style.transcript}>
			<div className={style.transcriptBody}>
				{transcript && transcript.length > 0 ? (
					transcript.map((block, blockIndex) => (
						<div
							key={`block-${blockIndex}-${
								block[0]?.startTime || blockIndex
							}`}
							className={`${style.transcriptItem} ${
								currentVideoSecond >= block[0]?.startSeconds &&
								currentVideoSecond <=
									block[block.length - 1]?.endSeconds
									? style.active
									: ""
							}`}
						>
							<div className={style.time}>
								<span>{block[0]?.startTime || "00:00"}</span>
							</div>

							<div className={style.text}>
								{block.map((transcriptItem, index) => (
									<mark
										className={
											currentVideoSecond >=
												transcriptItem.startSeconds &&
											currentVideoSecond <=
												block[block.length - 1]
													.endSeconds &&
											(index === block.length - 1 ||
												currentVideoSecond <
													block[index + 1]
														.startSeconds)
												? style.active
												: ""
										}
										key={`item-${index}-${
											transcriptItem.startTime || index
										}`}
										onClick={(e) => {
											e.stopPropagation();
											handleTimeClick(
												transcriptItem.startSeconds
											);
										}}
									>
										{transcriptItem.text}{" "}
									</mark>
								))}
							</div>
						</div>
					))
				) : (
					<div className={`${style.transcriptItem}`}>
						<div className={style.time}>
							<span>00:00</span>
						</div>
						<div className={style.text}>
							<mark>
								{isLoading
									? "Učitava se..."
									: isError
									? "Greška pri učitavanju transkripta"
									: "Ovaj video nema transkript"}
							</mark>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default TranscriptSection;
