import React, { useRef, useState, useEffect, useCallback } from "react";
import { FormProvider } from "react-hook-form";
import { usePostUnitComment } from "../../hooks/usePostUnitComment";
import FormTextArea from "@/features/forms/components/FormTextArea";
import { useFormWithValidation } from "@/features/forms/hooks/useFormWithValidation";
import EmojiPicker from "emoji-picker-react";
import Icon from "@/ui/icon/Icon";
import Button from "@/ui/button/Button";
import style from "./PostCommentForm.module.css";

function PostCommentForm({ video }) {
	const MAX_CONTENT_LENGTH = 300;

	const formMethods = useFormWithValidation({
		defaultValues: {
			content: "",
		},
		mode: "onChange",
		criteriaMode: "all",
	});

	const {
		handleSubmit,
		setError,
		setValue,
		reset,
		watch,
		formState: { isSubmitting, errors },
	} = formMethods;

	const { save } = usePostUnitComment({ unit: video });

	const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
	const emojiPickerRef = useRef(null);
	const [isPosting, setIsPosting] = useState(false);
	const textAreaRef = useRef(null);
	const content = watch("content");
	const contentRef = useRef(content);

	useEffect(() => {
		contentRef.current = content;
		setIsPosting(
			content.trim() !== "" && content.length <= MAX_CONTENT_LENGTH
		);
	}, [content, MAX_CONTENT_LENGTH]);

	const onSubmit = useCallback(
		async (data) => {
			await save({ content: data.content });
			reset({ content: "" });
			setIsPosting(false);
			setIsEmojiPickerVisible(false);
			if (textAreaRef.current) {
				textAreaRef.current.focus();
			}
		},
		[save, reset, setError]
	);

	const onEmojiClick = useCallback(
		(emojiData, event) => {
			if (!emojiData?.emoji) {
				console.error(
					"Emoji data is undefined. Check the onEmojiClick parameters."
				);
				return;
			}
			const currentContent = contentRef.current || "";
			if (
				currentContent.length + emojiData.emoji.length >
				MAX_CONTENT_LENGTH
			) {
				setError("content", {
					type: "maxLength",
					message: `Maximum length of ${MAX_CONTENT_LENGTH} characters exceeded.`,
				});
				return;
			}
			setValue("content", `${currentContent}${emojiData.emoji}`, {
				shouldValidate: true,
			});
		},
		[setValue, setError, MAX_CONTENT_LENGTH]
	);

	const toggleEmojiPicker = useCallback(() => {
		setIsEmojiPickerVisible((prev) => !prev);
	}, []);

	const handleClickOutside = useCallback((event) => {
		if (
			emojiPickerRef.current &&
			!emojiPickerRef.current.contains(event.target) &&
			!event.target.closest(`.${style.emojiToggleBtn}`)
		) {
			setIsEmojiPickerVisible(false);
		}
	}, []);

	useEffect(() => {
		if (isEmojiPickerVisible) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isEmojiPickerVisible, handleClickOutside]);

	const handleKeyDown = useCallback(
		(event) => {
			if (event.key === "Enter" && !event.shiftKey) {
				event.preventDefault();
				if (isPosting && !isSubmitting) {
					handleSubmit(onSubmit)();
				}
			} else if (event.key === "Escape") {
				reset({ content: "" });
				setIsPosting(false);
				setIsEmojiPickerVisible(false);
				if (textAreaRef.current) {
					textAreaRef.current.focus();
				}
			}
		},
		[handleSubmit, onSubmit, isPosting, isSubmitting, reset]
	);

	const handlePaste = useCallback(
		(event) => {
			const pastedData = event.clipboardData.getData("text");
			const maxLength = MAX_CONTENT_LENGTH;
			const currentContent = contentRef.current || "";
			if (currentContent.length + pastedData.length > maxLength) {
				event.preventDefault();
				const allowedText = pastedData.substring(
					0,
					maxLength - currentContent.length
				);
				setValue("content", currentContent + allowedText, {
					shouldValidate: true,
				});
				setError("content", {
					type: "maxLength",
					message: `Maximum length of ${MAX_CONTENT_LENGTH} characters exceeded.`,
				});
			}
		},
		[setValue, setError, MAX_CONTENT_LENGTH]
	);

	const handleChange = useCallback(
		(e) => {
			const newValue = e.target.value;
			if (newValue.length <= MAX_CONTENT_LENGTH) {
				setValue("content", newValue, { shouldValidate: true });
				if (errors.content?.type === "maxLength") {
					setError("content", { ...errors.content, message: "" });
				}
			} else {
				setValue("content", newValue.substring(0, MAX_CONTENT_LENGTH), {
					shouldValidate: true,
				});
				setError("content", {
					type: "maxLength",
					message: `Maximum length of ${MAX_CONTENT_LENGTH} characters exceeded.`,
				});
			}
		},
		[setValue, setError, MAX_CONTENT_LENGTH, errors.content]
	);

	const handleCancel = useCallback(() => {
		reset({ content: "" });
		setIsPosting(false);
		setIsEmojiPickerVisible(false);
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	}, [reset]);

	return (
		<FormProvider {...formMethods}>
			<form
				className={`${style.postForm} ${isPosting ? style.active : ""}`}
				onSubmit={handleSubmit(onSubmit)}
				noValidate={true}
			>
				<FormTextArea
					name="content"
					rows={3}
					required
					placeholder="Postavite pitanje"
					className={style.input}
					onKeyDown={handleKeyDown}
					onPaste={handlePaste}
					onChange={handleChange}
					aria-invalid={errors.content ? "true" : "false"}
					aria-describedby={
						errors.content ? "content-error" : undefined
					}
					ref={textAreaRef}
				/>
				{/*errors.content && (
					<span id="content-error" className={style.error}>
						{errors.content.message}
					</span>
				)*/}
				<div className={style.footer}>
					<div className={style.emojiHolder}>
						<div className={style.msgLength}>
							<span
								className={
									content.length > MAX_CONTENT_LENGTH
										? style.limit
										: ""
								}
							>
								{content.length}
							</span>
							<span>/</span>
							<span>{MAX_CONTENT_LENGTH}</span>
						</div>
						<button
							onClick={toggleEmojiPicker}
							type="button"
							className={`${style.emojiToggleBtn} ${
								isEmojiPickerVisible ? style.active : ""
							}`}
							aria-label="Toggle Emoji Picker"
						>
							<Icon icon="fa-smile" />
						</button>
					</div>
					<div className={style.btnsHolder}>
						{isPosting && (
							<Button
								onClick={handleCancel}
								className={style.cancelBtn}
							>
								<Icon icon="fa-times" />
							</Button>
						)}
						<Button
							className={style.sendBtn}
							disabled={
								isSubmitting ||
								!isPosting ||
								content.length > MAX_CONTENT_LENGTH
							}
							type="accent"
							aria-disabled={
								isSubmitting ||
								!isPosting ||
								content.length > MAX_CONTENT_LENGTH
							}
						>
							{isSubmitting ? (
								"..."
							) : (
								<span>
									<Icon icon="fa-paper-plane-top" />
								</span>
							)}
						</Button>
					</div>
					{isEmojiPickerVisible && (
						<div
							className={style.emojiPickerContainer}
							ref={emojiPickerRef}
						>
							<EmojiPicker onEmojiClick={onEmojiClick} />
						</div>
					)}
				</div>
			</form>
		</FormProvider>
	);
}

export default PostCommentForm;
