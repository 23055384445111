export const BASE_URL =
	import.meta.env.VITE_API_BASE_URL || "http://localhost:8000/";
export const AUTH_TOKEN_LOCAL_STORAGE_ITEM_NAME = "auth-token";
export const APP_NAME = "Dekamas";
export const GOOGLE_REDIRECT_URI =
	import.meta.env.VITE_GOOGLE_REDIRECT_URI ||
	"http://localhost:5173/login/google";
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
export const DJANGO_ADMIN_URL =
	import.meta.env.VITE_DJANGO_ADMIN_URL ||
	"https://api.dekamas.com/django-admin/";

export const UMAMI_SCRIPT_URL = import.meta.env.VITE_UMAMI_SCRIPT_URL;
export const UMAMI_WEBSITE_ID = import.meta.env.VITE_UMAMI_WEBSITE_ID;

export const COMPLETE_COURSE_THRESHOLD = 90;
