import style from "./AiPopup.module.css";

import Icon from "@/ui/icon/Icon";
import Modal from "@/ui/modal/Modal";
import Logo from "@/ui/logo/Logo";

import AiCreditsCard from "../aiCreditsCard/AiCreditsCard";
import AiPostQuestionForm from "../aiPostQuestionForm/AiPostQuestionForm";
import NotActiveSession from "../notActiveSession/NotActiveSession";
import AiChatSession from "../aiChatSession/AiChatSession";
import AiChatHistory from "../aiChatHistory/AiChatHistory";
import AIPopupContext from "../../contexts/AIPopupContext";
import { useContext, useEffect, useState } from "react";
import { useGetThreads } from "../../hooks/useGetThreads";
import { useGetRuns } from "../../hooks/useGetRuns";
import { getRun } from "../../api";
import { useQueryClient } from "@tanstack/react-query";

function AiPopup({ currentUnit }) {
	const { threads } = useGetThreads();
	const [currentThread, setCurrentThread] = useState(null);
	const [runToRefetch, setRunToRefetch] = useState(null);
	const [prefilledAnswer, setPrefilledAnswer] = useState(null);

	const { runs } = useGetRuns({ thread_uuid: currentThread });

	const queryClient = useQueryClient();

	const thinking_statuses = [
		"created",
		"queued",
		"in_progress",
		"cancelling",
	];
	const error_statuses = [
		"requires_action",
		"cancelled",
		"failed",
		"incomplete",
		"expired",
	];
	const success_statuses = ["completed"];

	useEffect(() => {
		if (!runToRefetch) return;

		let timeout = null;

		const refetch = async () => {
			try {
				const run = await getRun({ run_uuid: runToRefetch });

				if (run.message || !thinking_statuses.includes(run?.status)) {
					// Snapshot the previous value
					const previousRuns = queryClient.getQueryData([
						"ai",
						"threads",
						run?.thread_uuid,
					]);

					// Optimistically update to the new value
					queryClient.setQueryData(
						["ai", "threads", run?.thread_uuid],
						(old) => {
							if (old.find((item) => item?.uuid == run?.uuid))
								return old.map((item) =>
									item?.uuid == run?.uuid ? run : item
								);
							return [...old, run];
						}
					);

					if (
						error_statuses.includes(run?.status) ||
						success_statuses.includes(run?.status)
					) {
						queryClient.invalidateQueries({
							queryKey: ["ai", "threads", run?.thread_uuid],
						});

						queryClient.invalidateQueries({
							queryKey: ["ai", "token-usage"],
						});

						setRunToRefetch(null);
						return;
					}
				}
			} catch (e) {
				if (e?.status == 404 || e?.status == 500 || e?.status == 422) {
					setRunToRefetch(null);
					return;
				}
			}

			timeout = setTimeout(refetch, 250);
		};

		timeout = setTimeout(refetch, 250);

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [runToRefetch]);

	return (
		<AIPopupContext.Provider
			value={{
				threads,
				currentThread,
				setCurrentThread,
				runs,
				currentUnit,
				runToRefetch,
				setRunToRefetch,
				prefilledAnswer,
				setPrefilledAnswer,
			}}
		>
			<section className={style.aiPopup}>
				<div className={style.body}>
					<div className={style.grid}>
						<div className={style.sideBar}>
							<div className={style.sideHeader}>
								<div className={style.logo}>
									<Logo />
									<span>Inteligentni Asistent</span>
								</div>
								<div
									onClick={() => setCurrentThread(null)}
									className={style.newSession}
								>
									<span>Zapocni novu sesiju</span>
									<span className={style.icoHolder}>
										<Icon icon="fa-plus" />
									</span>
								</div>
							</div>
							<div className={style.sideBody}>
								<AiChatHistory />
							</div>
							<div className={style.sideFooter}>
								<AiCreditsCard />
							</div>
						</div>
						<div className={style.main}>
							<div className={style.mainHeader}>
								<ContextElement />
								<div className={style.closeButton}>
									<Modal.Close />
								</div>
							</div>
							<div className={style.mainBody}>
								{currentThread ? (
									<AiChatSession />
								) : (
									<NotActiveSession />
								)}
							</div>
							<div className={style.mainFooter}>
								<AiPostQuestionForm inSession={true} />
							</div>
						</div>
					</div>
				</div>
			</section>
		</AIPopupContext.Provider>
	);
}

function ContextElement() {
	const { threads, currentThread, currentUnit } = useContext(AIPopupContext);
	const thread = threads.find((thread) => thread.uuid == currentThread);

	if (currentUnit && (!thread || thread.uuid == currentUnit?.course?.uuid))
		return (
			<div className={style.contextElement}>
				<div className={style.imgHolder}>
					<img src={currentUnit?.video?.thumbnail} alt="thumbnail" />
				</div>
				<div className={style.moreInfo}>
					<span>Kontekst:</span>
					<span>{currentUnit?.video?.name}</span>
				</div>
			</div>
		);

	if (thread && thread?.course)
		return (
			<div className={style.contextElement}>
				<div className={style.imgHolder}>
					<img src={thread?.course?.thumbnail} alt="thumbnail" />
				</div>
				<div className={style.moreInfo}>
					<span>Kontekst:</span>
					<span>{thread?.course?.name}</span>
				</div>
			</div>
		);
	return <div></div>;
}

export default AiPopup;
