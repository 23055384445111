import style from "./AboutCourseSection.module.css";
import { makeTagColors, secondsToHoursMinutes } from "@/utils/helpers";
import { useState } from "react";
import Icon from "@/ui/icon/Icon";

function AboutCourseSection({ unit }) {
	return (
		<div className={style.grid}>
			<div
				className={style.description}
				dangerouslySetInnerHTML={{
					__html: unit?.course?.description,
				}}
			/>
			<div className={style.other}>
				<Lecturers lecturers={unit.course.lecturers} />
				<CourseStats course={unit.course} />
			</div>
		</div>
	);
}

function Lecturers({ lecturers }) {
	return (
		<>
			{lecturers.map((lecturer, index) => (
				<LecturerCard key={index} lecturer={lecturer} />
			))}
		</>
	);
}

function LecturerCard({ lecturer }) {
	const [isExpanded, setIsExpanded] = useState(false);
	const maxLength = 190; // characters to show initially

	const truncatedBio = lecturer?.bio?.slice(0, maxLength);
	const shouldShowButton = lecturer?.bio?.length > maxLength;

	return (
		<div className={style.lecturer}>
			<div className={style.header}>
				<div className={style.imgHolder}>
					<img src={lecturer?.picture_url} alt="Profile" />
				</div>
				<div className={style.info}>
					<div className={style.nameHolder}>
						<div className={style.fullName}>{lecturer?.name}</div>
						<div className={style.icoHolder}>
							<Icon iconType="fa-solid" icon="fa-badge-check" />
						</div>
					</div>
					<div className={style.jobTitle}>{lecturer?.job_title}</div>
				</div>
			</div>
			<div className={style.body}>
				<span className={style.bio}>
					{isExpanded ? lecturer?.bio : truncatedBio}
					{!isExpanded && shouldShowButton && "..."}
				</span>
				{shouldShowButton && (
					<button
						className={style.showMoreBtn}
						onClick={() => setIsExpanded(!isExpanded)}
					>
						{isExpanded ? "Vidi manje" : "Vidi više"}
					</button>
				)}
			</div>
		</div>
	);
}

function CourseStats({ course }) {
	const difficultyTranslations = {
		easy: "Početna",
		medium: "Srednja",
		hard: "Napredna",
	};

	const stats = [
		{
			label: "Težina",
			value:
				(course?.difficulty &&
					difficultyTranslations[course.difficulty]) ||
				"Početna",
			icon: "fa-bullseye",
		},
		{
			label: "Trajanje",
			value:
				(course.duration && secondsToHoursMinutes(course?.duration)) ||
				0,
			icon: "fa-clock",
		},
		{
			label: "Moduli",
			value: course?.module_count || 0,
			icon: "fa-layer-group",
		},
	];

	return (
		<div className={style.courseStats}>
			<div className={style.statsGrid}>
				{stats.map((stat, index) => (
					<div key={index} className={style.statItem}>
						<div className={style.icoHolder}>
							<Icon icon={stat.icon} />
						</div>
						<div className={style.statInfo}>
							<span className={style.statLabel}>
								{stat.label}
							</span>
							<span className={style.statValue}>
								{stat.value}
							</span>
						</div>
					</div>
				))}
			</div>

			<div className={style.categorySection}>
				<div className={style.category}>
					{course?.categories.map((category) => (
						<span
							// style={makeTagColors(category.name)}
							key={category.slug}
						>
							{category.name}
						</span>
					))}
				</div>
			</div>
		</div>
	);
}

export default AboutCourseSection;
