import style from "./SearchItem.module.css";
import Icon from "@/ui/icon/Icon";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Command } from "cmdk";

import { secondsToHoursMinutes } from "@/utils/helpers";

function SearchItem({ item, type }) {
	const navigate = useNavigate();

	return (
		<Command.Item
			className={style.item}
			keywords={[item.title, ...(item?.lecturers || [])]}
			value={item.id}
			onSelect={() => {
				navigate(item.url);
			}}
		>
			<div className={style.thumbnail}>
				{type == "kvizovi" && (
					<div className={style.quizThumbnail}>
						<img src={item?.thumbnail} alt={item.title} />
						<div className={style.icoHolder}>
							<Icon iconType="fa-solid" icon="fa-question" />
						</div>
					</div>
				)}
				{item?.thumbnail ? (
					<img src={item?.thumbnail} alt={item.title} />
				) : (
					<div className={style.quizThumbnail}>
						<Icon icon="fa-question" />
					</div>
				)}
			</div>

			<div className={style.info}>
				<div className={style.header}>
					<h3 title={item.title} className={style.title}>
						{item.title}
					</h3>
					<div className={style.category}>
						<span>{item.category}</span>
						<Icon
							icon={
								{
									tehnologija: "fa-desktop",
									biznis: "fa-briefcase-blank",
									kreativa: "fa-palette",
								}[item.category]
							}
						/>
					</div>
				</div>
				{type == "kursevi" && (
					<div className={style.body}>
						{item.lecturers.map((lecturer) => (
							<div className={style.authorsHolder} key={lecturer}>
								<div className={style.author}>{lecturer}</div>

								<span className={style.sep}></span>
							</div>
						))}
						<div>
							<Icon icon="fa-heart" />
							<span>{item.likes}</span>
						</div>
						<span className={style.sep}></span>
						<div>
							<Icon icon="fa-layer-group" />
							<span>{item.modules}</span>
						</div>
						<span className={style.sep}></span>
						<div>
							<Icon icon="fa-timer" />
							<span>{secondsToHoursMinutes(item.duration)}</span>
						</div>
					</div>
				)}
				{type == "video" && (
					<div className={style.body}>
						<div>
							<Icon icon="fa-heart" />
							<span>{item.likes}</span>
						</div>
						<span className={style.sep}></span>
						<div>
							<Icon icon="fa-timer" />
							<span>{secondsToHoursMinutes(item.duration)}</span>
						</div>
						<span className={style.sep}></span>
						<div>
							<Icon icon="fa-eye" />
							<span>{item.views}</span>
						</div>
					</div>
				)}
				{type == "kvizovi" && (
					<div className={style.body}>
						<div className={style.questions}>
							<span>Broj pitanja</span>
							<span>
								<b>{item.questions} </b>
							</span>
						</div>
					</div>
				)}
				{type == "path" && (
					<div className={style.body}>
						<div>
							<Icon icon="fa-chalkboard" />
							<span>
								<b>{item.courseCount} </b>
							</span>
						</div>
						<span className={style.sep}></span>
						<div className={style.questions}>
							<span>ECTS</span>
							<span>
								<b>{item.ectsCredits} </b>
							</span>
						</div>
					</div>
				)}
			</div>
		</Command.Item>
	);
}

export default SearchItem;
