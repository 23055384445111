import { useState, useEffect, useRef, useCallback, memo } from "react";
import style from "./CourseWatching.module.css";
import Container from "@/layouts/container/Container";

import Quiz from "@/features/quiz/Quiz";

import LikeCounter from "@/ui/likeCounterButton/LikeCounterButton";

import Icon from "@/ui/icon/Icon";

import CourseTabs from "@/features/courses/components/courseTabs/CourseTabs";

import { Link, useParams } from "react-router-dom";

import ContentTable from "@/features/video/components/contentTable/ContentTable";
import { useGetUnit } from "@/features/courses/hooks/useGetUnit";

import { useUpdateUserActivity } from "@/features/courses/hooks/useUpdateUserActivity";
import { useQueryClient } from "@tanstack/react-query";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import { getLabel, timeAgo } from "@/utils/helpers";
import { useMediaQuery } from "@/hooks/useMediaQuery"; // Import useMediaQuery

function CourseWatching() {
	const { courseSlug, moduleSlug, unitSlug } = useParams();
	const unitQuery = useGetUnit({ courseSlug, moduleSlug, unitSlug });

	const [currentVideoSecond, setCurrentVideoSecond] = useState(0);
	const isMobile = useMediaQuery("(max-width: 1000px)"); // Use useMediaQuery to check for mobile device

	const [forceVideoSecond, setForceVideoSecond] = useState(null);

	useEffect(() => {
		const oldTitle = document.title;

		if (unitQuery.isSuccess && unitQuery.data.unit_type == "video") {
			document.title = `${unitQuery.data.video.name} - ${unitQuery.data.video.module.course.name}`;
		}

		return () => {
			document.title = oldTitle;
		};
	}, [unitQuery?.isSuccess, unitQuery?.data?.video?.name]);

	if (unitQuery.isError) return <div>Error</div>;

	return (
		<div className={style.grid}>
			<main className={style.main}>
				{unitQuery.isSuccess ? (
					<>
						<Container size="medium">
							{unitQuery.data.unit_type == "video" && (
								<div className={style.mainContent}>
									<div className={style.header}>
										<Link className={style.goBack} to="/">
											<Icon icon="fa-arrow-left" />
										</Link>

										<div className={style.breadcrumb}>
											<div className={style.item}>
												<span>
													{
														unitQuery.data.video
															.module.course.name
													}
												</span>
												<div
													className={style.separator}
												>
													<Icon icon="fa-slash-forward" />
												</div>
											</div>
											<div className={style.item}>
												<span>
													{
														unitQuery.data.video
															.module.name
													}
												</span>
												<div
													className={style.separator}
												>
													<Icon icon="fa-slash-forward" />
												</div>
											</div>
											<div className={style.item}>
												<span>
													{unitQuery.data.video.name}
												</span>
											</div>
										</div>
									</div>
									<Video
										key={`${unitQuery.data.video?.module?.course?.slug}-${unitQuery.data.video?.module?.slug}-${unitQuery.data.video?.slug}`}
										video={unitQuery.data.video}
										onSecondChange={setCurrentVideoSecond}
										forceVideoSecond={forceVideoSecond}
										setForceVideoSecond={
											setForceVideoSecond
										}
									/>

									<CourseTabs
										unit={unitQuery.data}
										currentVideoSecond={currentVideoSecond}
										onVideoTimeChange={setForceVideoSecond}
										activeTab="reviews"
									/>
								</div>
							)}
						</Container>
						{unitQuery.data.unit_type == "quiz" && (
							<Quiz quiz={unitQuery.data.quiz} />
						)}
					</>
				) : (
					<FullPageLoader />
				)}
			</main>
			{!isMobile && <ContentTable />}
		</div>
	);
}

function Video({
	video,
	onSecondChange,
	forceVideoSecond,
	setForceVideoSecond,
}) {
	const [player, setPlayer] = useState(null);
	const timetrackingState = useRef(null);

	const { save } = useUpdateUserActivity({ unit: video });
	const queryClient = useQueryClient();

	const [startTime, setStartTime] = useState(
		video?.user_activity?.current_second_in_video || 0
	);

	// Todo: add time tracking && debounced timeupdate

	function calculateWatchtime() {
		const state = timetrackingState.current;
		if (!state) return;

		// Only update if playing
		if (state.isPlaying) {
			state.watchTimeToSync += (Date.now() - state.startedPlaying) / 1000;
			state.totalWatchTime += (Date.now() - state.startedPlaying) / 1000;
		}

		state.startedPlaying = Date.now();
	}

	async function syncData() {
		if (!timetrackingState.current.fullyLoaded) return;

		calculateWatchtime();
		const state = timetrackingState.current;
		if (!state || state.isSubmitting) return;

		state.isSubmitting = true;
		const duration = Math.floor(state.watchTimeToSync);
		state.watchTimeToSync -= duration;

		const count_view = state.totalWatchTime > 10 && !state.countedView;

		const data = {
			duration: duration,
			unix_timestamp: Math.floor(Date.now() / 1000),
			current_second_in_video: Math.floor(state.currentTime),
			is_completed: state.isCompleted,
			count_view: count_view,
		};

		try {
			if (
				!state.oldData ||
				data.duration > 0 ||
				data.current_second_in_video !=
					state.oldData?.current_second_in_video ||
				data.count_view != state.oldData?.count_view ||
				data.is_completed != state.oldData?.is_completed
			) {
				const response = await save(data);
				state.oldData = data;

				if (count_view) state.countedView = true;

				if (response?.shouldRefetchUnits) {
					queryClient.invalidateQueries({
						queryKey: [
							"courses",
							video.module.course.slug,
							"units",
							"list",
						],
					});
				}
			}
		} catch (e) {
			// pass
		}

		state.isSubmitting = false;
	}

	function startedPlaying() {
		calculateWatchtime();
		timetrackingState.current.startedPlaying = Date.now();
		timetrackingState.current.isPlaying = true;
		timetrackingState.current.fullyLoaded = true;
	}

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (!player) return;

			if (e.key === "ArrowRight") {
				player.getCurrentTime((value) => {
					player.setCurrentTime(value + 10);
				});
			} else if (e.key === "ArrowLeft") {
				player.getCurrentTime((value) => {
					player.setCurrentTime(value - 10);
				});
			}
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [player]);

	useEffect(() => {
		if (!player || !forceVideoSecond) return;

		player.setCurrentTime(forceVideoSecond);
		setForceVideoSecond(null);
	}, [player, forceVideoSecond]);

	useEffect(() => {
		if (!player) return;

		console.log("Player is", player);
		player.on("ready", () => {
			player.on("timeupdate", (e) => {
				if (!timetrackingState?.current?.isPlaying) {
					startedPlaying();
				}

				timetrackingState.current.currentTime = e.seconds;
				// Mark as completed in the last three seconds
				if (e.seconds >= e.duration * 0.8 && e.duration > 1)
					timetrackingState.current.isCompleted = true;
			});

			player.on("pause", (e) => {
				calculateWatchtime();
				timetrackingState.current.isPlaying = false;
			});

			player.on("error", (e) => {
				player.getPaused(function (paused) {
					if (paused) {
						calculateWatchtime();
						timetrackingState.current.isPlaying = false;
					}
				});
			});

			player.on("play", (e) => {
				if (!timetrackingState?.current?.isPlaying) {
					startedPlaying();
				}
			});
		});

		console.log("ALIVE!", player);

		timetrackingState.current = {
			startedPlaying: Date.now(),
			watchTimeToSync: 0,
			totalWatchTime: 0,
			currentTime: 0,
			isCompleted: false,
			isPlaying: false,
			countedView: false,
		};

		const syncInterval = setInterval(syncData, 1000); // TODO: benchmark this
		const checkPausedInterval = setInterval(() => {
			player.getPaused(function (paused) {
				if (paused && timetrackingState?.current?.isPlaying) {
					calculateWatchtime();
					timetrackingState.current.isPlaying = false;
				}
			});

			player.getCurrentTime((value) => onSecondChange(value));
		}, 500);

		return () => {
			if (player) player.off();

			// Clear intervals and reset state
			clearInterval(syncInterval);
			clearInterval(checkPausedInterval);

			timetrackingState.current = null;
		};
	}, [player]);

	const iframeRef = useCallback((iframe) => {
		if (!iframe) return;

		setPlayer(new window.playerjs.Player(iframe));
	}, []);

	return (
		<div className={style.videoHolder}>
			<div className={style.video}>
				<MyPureIframe
					src={
						`https://iframe.mediadelivery.net/embed/${video.bunny_library_id}/${video.bunny_video_id}` +
						`?autoplay=true&loop=false&muted=false&preload=true&responsive=true&t=${startTime}`
					}
					iframeRef={iframeRef}
				/>
				<div className={style.loader}></div>
				{/* <div className={style.loaderHolder}>
					<div className={style.loader}></div>
				</div> */}
			</div>
			<div className={style.videoInfo}>
				<div className={style.title}>
					<h1>{video.name}</h1>
					<div className={style.subInfo}>
						<span>
							{video?.view_count}{" "}
							{getLabel(video?.view_count, {
								singular: "pregled",
								dual: "pregleda",
								plural: "pregleda",
							})}
						</span>
						<span className={style.separator}></span>
						<span>
							{timeAgo(new Date(video?.upload_date).getTime())}
						</span>
					</div>
				</div>
				<div className={style.moreOptions}>
					<LikeCounter video={video} />
				</div>
			</div>
		</div>
	);
}
// eslint-disable-next-line react/display-name
const MyPureIframe = memo(({ src, iframeRef }) => {
	return (
		<iframe
			ref={iframeRef}
			src={src}
			loading="lazy"
			allow="autoplay;accelerometer;gyroscope;encrypted-media;picture-in-picture;"
			allowFullScreen={true}
		></iframe>
	);
});

export default CourseWatching;
