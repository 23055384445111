import style from "./PathPreview.module.css";
import { Link, useParams } from "react-router-dom";

import Icon from "@/ui/icon/Icon";
import Container from "@/layouts/container/Container";

import PathDetails from "@/features/paths/components/pathDetails/PathDetails";
import PathCategories from "@/features/paths/components/pathCategories/PathCategories";
import PathObtainCertificate from "@/features/paths/components/pathObtainCertificate/PathObtainCertificate";
import PathCourses from "@/features/paths/components/pathCourses/PathCourses";
import { useGetPath } from "@/features/paths/hooks/useGetPath";
import FullPageLoader from "@/ui/fullPageLoader/FullPageLoader";
import PathPreviewContext from "@/features/paths/contexts/PathPreviewContext";

function PathPreview() {
	const { pathSlug } = useParams();

	const { path, isPending } = useGetPath({ pathSlug });

	if (isPending) return <FullPageLoader />;

	return (
		<PathPreviewContext.Provider value={{ path }}>
			<Container>
				<section>
					<div className={style.grid}>
						<div>
							<div className={style.thumbnailHolder}>
								<Link to="/paths" className={style.goBack}>
									<div className={style.icoHolder}>
										<Icon
											icon="fa-arrow-left"
											iconType="fa-solid"
										/>
									</div>
									<span to="/paths" className={style.text}>
										vrati se nazad
									</span>
								</Link>
								<img
									src={path.thumbnail}
									alt="path thumbnail"
								/>
							</div>
							<div className={style.content}>
								<h2>{path.name}</h2>
								<PathCategories />
								<p>{path.description}</p>
							</div>
							<div className={style.obtainCertificate}>
								<PathObtainCertificate />
							</div>
							<div className={style.coursesHolder}>
								<PathCourses />
							</div>
						</div>
						<div className={style.details}>
							<PathDetails />
						</div>
					</div>
				</section>
			</Container>
		</PathPreviewContext.Provider>
	);
}

export default PathPreview;
