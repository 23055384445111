import { removeCjelina } from "@/utils/helpers";
import style from "./PathCourseCard.module.css";

function PathCourseCard({ course, index }) {
	return (
		<div className={style.cardHolder}>
			<div className={style.numeration}>{index + 1}.</div>
			<div
				className={style.card}
				style={{ "--background": `url(${course.thumbnail})` }}
			>
				<div className={style.courseInfo}>
					<h3 title={course.name}>{course.name}</h3>
					<p>{course.short_description}</p>
				</div>
				<div className={style.modulesHolder}>
					<div className={style.moduleOverview}>
						{course?.modules &&
							course.modules.map((module, index) => (
								<div className={style.module} key={module.slug}>
									<div className={style.circle}></div>
									<div className={style.info}>
										<h4>Cjelina {index + 1}</h4>
										<p title={removeCjelina(module.name)}>
											{removeCjelina(module.name)}
										</p>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PathCourseCard;
