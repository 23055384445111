import { useState, useEffect, useRef } from "react";
import style from "./MarkAsCompletedButton.module.css";
import Icon from "@/ui/icon/Icon";
import correctSound from "@/assets/sounds/correct_answer.mp3";
import confetti from "canvas-confetti";
import { useFinishCourse } from "../../hooks/useFinishCourse";

function MarkAsCompletedButton({ course }) {
	const [isVisible, setIsVisible] = useState(true);
	const [animate, setAnimate] = useState(false);
	const buttonRef = useRef(null);
	const audioRef = useRef(null);

	const { finishCourse } = useFinishCourse({ course });

	useEffect(() => {
		setAnimate(true);
		return () => {
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current = null;
			}
		};
	}, []);

	const handleClick = async () => {
		try {
			await finishCourse();

			// Left corner confetti
			confetti({
				particleCount: 80,
				spread: 70,
				origin: { x: 0.1, y: 0.8 },
			});

			// Right corner confetti
			confetti({
				particleCount: 80,
				spread: 70,
				origin: { x: 0.9, y: 0.8 },
			});

			audioRef.current = new Audio(correctSound);
			await audioRef.current.play();
			setAnimate(false);
			setTimeout(() => {
				setIsVisible(false);
			}, 200);
		} catch (error) {
			console.error("Error playing sound:", error);
			setAnimate(false);
			setTimeout(() => {
				setIsVisible(false);
			}, 200);
		}
	};

	return (
		<>
			{isVisible && (
				<div
					ref={buttonRef}
					className={`${style.buttonHolder} ${
						animate ? style.fadeIn : style.fadeOut
					}`}
				>
					<div className={style.button} onClick={handleClick}>
						<span>Označi kao pregledano</span>
						<div className={style.icoHolder}>
							<span className={style.defaultIcon}>
								<Icon icon="fa-eye" />
							</span>
							<span className={style.hoverIcon}>
								<Icon icon="fa-check" />
							</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default MarkAsCompletedButton;
